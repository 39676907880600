@import './../../../styles/root.scss';

.steps_section {
    padding: 120px 0px;

    .steps_text {
        h2 {
            @include heading1;
            margin-bottom: 40px;
        }
    }
}