
$white: #ffffff;
$white-light: #e7e7e7;
$aqua-light: #F1FBF5;
$aqua: #7AFADA;
$aqua-dark: #08E8AF;
$chin: #A6E7C212;
$nile: #133D52;
$dusk: #465475;
$mag: #F4F6FB;
$black-light: #040505;
$black: #000000;

$mono: 'Montserrat', sans-serif;
$corm: 'Cormorant Garamond', serif;

$z1: 2;
$z2: 1;
$z3: 3;

@mixin heading1 {
    color: $black-light;
    font-family: $corm;
    font-size: 50px;
    font-weight: 700;

    span {
        color: $aqua;
    }
}

@mixin heading2 {
    color: $white;
    font-family: $corm;
    font-size: 30px;
    font-weight: 400;
}

@mixin heading3 {
    color: $black-light;
    font-family: $corm;
    font-size: 40px;
    font-weight: 400;
}

@mixin pera1 {
    color: $dusk;
    font-family: $mono;
    font-size: 22px;
    font-weight: 400;
}

@mixin pera2 {
    color: $dusk;
    font-family: $mono;
    font-size: 18px;
    font-weight: 400;
}

@mixin button1 {
    padding: 15px 30px;
    font-family: $mono;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: $black;
    background-color: $white;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: $aqua;
        box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
        color: $white;
        transform: translateY(-7px);
    }
}


@function t($time) {
    @return all linear $time+s;
}

