@import './../../../styles/root.scss';

.blog_menu {
    padding: 0px 24px 30px;

    .search_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 24px 24px 0px;
        gap: 14px;

        input {
            border: none;
            border-radius: 0px;
            background-color: #f4f6f5;
            padding: 10px 12px;
            font-size: 12px;
            flex-grow: 2;
        }

        button {
            padding: 10px 20px;
            background-color: $aqua;
            border: none;
            border-radius: 0px;
            cursor: pointer;
            font-family: $mono;
            color: $black-light;
            font-size: 12px;
            font-weight: 500;
        }
    }

    h2 {
        font-family: $mono;
        color: $black-light;
        font-size: 24px;
        font-weight: 700;
        margin: 30px 0px;
    }

    .recent_posts {
        ul {
            padding: 0;
        }
    }

    .categories {
        ul {
            padding: 0px;
        }
    }

    .populer_tags {
        ul {
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            li {
                padding: 12px 16px;
                background-color: #c4faea;
                border-radius: 8px;
                font-family: $corm;
                font-size: 14px;
                color: #227d49;
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .blog_menu {
        padding: 0px 5px;

        .search_box {
            padding: 24px 4px 4px 0px;
            gap: 4px;

            input {
                padding: 10px 2px;
                font-size: 10px;
            }

            button {
                padding: 10px 10px;
                font-size: 10px;
            }
        }

        h2 {
            font-size: 18px;
        }

        .recent_posts {
            ul {
                li {
                    gap: 5px;

                    .imgbox {
                        height: 50px;
                        width: 50px;
                        border-radius: 50px;
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }
        }

        .categories {
            ul {
                li {
                    font-size: 12px;

                    input {
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }

        .populer_tags {
            ul {
                gap: 6px;

                li {
                    padding: 6px 10px;
                    font-size: 12px;
                }
            }
        }
    }
}