@import './../../../styles/root.scss';

.features_section{
    padding: 120px 0px;
    background-color: $nile;
    h2{
        @include heading1;
        color: $white;
        text-align: center
    }
    p{
        @include pera1;
        text-align: center;
        color: $white;
        margin-bottom: 40px;
    }
}