@import './../../../styles/root.scss';

.about_section{
    background-color: #fff;
    .about_img{
        padding: 120px;
    }
    .about_text{
        padding: 20px 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h2{
            @include heading1;
        }
        h3{
            font-family: $mono;
            font-size: 18px;
            font-weight: 700;
            margin-top: 24px;
            color: $aqua;
        }
        p{
            @include pera1;
            margin-top: 24px;
            margin-bottom: 20px;
        }
        a{
            @include button1;
            margin-top: 20px;
        }
    }
}