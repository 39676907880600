@import './../../../styles/root.scss';

.c_button {
    padding: 15px 30px;
    font-family: $mono;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: $black;
    background-color: $white;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: $aqua;
        box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
        color: $white;
        transform: translateY(-7px);
    }
}