@import './../../../styles/root.scss';

.faq_accordion {
    .accordion-item {
        margin-bottom: 16px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        border: none;
        border-radius: 0px;
        outline: none;
        padding: 10px 12px;

        .accordion-button {
            font-family: $mono;
            font-size: 16px;
            font-weight: 700;
            color: $dusk;
            span{
                padding: 7px;
                background-color: #133D5220;
                border-radius: 50%;
                margin-right: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    color: $black;
                }
            }
            &::after{
                display: none;
            }
            &:not(.collapsed) {
                color: $black;
                background-color: $white;
            }
            &:focus {
                border-color: transparent;
                box-shadow: none;
            }
        }
        .accordion-body{
            @include pera1;
            font-size: 15px;
        }
    }
}

.more_accordion {
    .accordion-item {
        margin-bottom: 8px;
        background: $mag;
        border: none;
        border-radius: 0px;
        outline: none;
        padding: 5px;

        .accordion-button {
            font-family: $mono;
            font-size: 20px;
            font-weight: 700;
            color: $black-light;
            background: $mag;

            svg {
                color: $black-light;
                font-size: 25px;
                margin-right: 5px;
            }

            &::after {
                display: none;
            }

            &:not(.collapsed) {
                color: $black-light;
                background-color: $mag;
            }

            &:focus {
                border-color: transparent;
                box-shadow: none;
            }
        }

        .accordion-body {
            @include pera1;
            font-size: 15px;
            background-color: $white;
        }
    }
}