.ratings {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    li {
        color: #08E8AF50;
        font-size: 20px;

        i {
            user-select: none;
            &.fill {
                color: #08E8AF;
            }
        }
    }
}