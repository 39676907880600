@import './../../../styles/root.scss';

.contact_box {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon_box {
        svg {
            font-size: 40px;
            color: $aqua;
        }
    }

    .text_box {
        h2 {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 2px;
        }

        p {
            color: $white-light;
            font-size: 15px;
            margin-bottom: 0px;
            cursor: text;
        }
    }
}