@import './../../../styles/root.scss';

.card {
    background-color: $white;
    border: none;
    border-radius: 0px;
    box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.08);

    .head {
        position: relative;
        padding: 0;
        margin: 0;

        .tag_list {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            padding: 10px;

            li {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 16px 20px;
                border-radius: 40px;
                background-color: $white;
                font-family: $mono;
                font-size: 16px;
                color: $black-light;

                &.bg_aqua {
                    background-color: $aqua;
                }
            }
        }
    }

    .body {
        padding: 20px;
        text-align: left;
        h2 {
            @include heading2;
            color: $black-light;
        }

        p {
            @include pera1;
            font-size: 20px;
            margin-top: 14px;
        }

        .tag_list {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0;
            margin-top: 16px;

            li {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 16px 20px;
                border-radius: 40px;
                border: 0.5px solid #A6B0CA;
                font-family: $mono;
                font-size: 16px;
                color: $black-light;

                &.bg_aqua {
                    background-color: $aqua;
                }

                &.img_tag {
                    padding: 5px 16px 5px 5px;
                }

                .imgbox {
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                }
            }
        }
    }
}