@import './../../../styles/root.scss';


.courses_section {
    padding: 120px 0px;
    background-color: #fff;

    .heading_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        h2 {
            font-family: $corm;
            font-size: 40px;
            font-weight: 400;
            color: $black-light;
        }

        .categories {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 20px 40px;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
            border-radius: 40px;
            margin-bottom: 30px;

            li {
                font-family: $mono;
                font-size: 16px;
                color: $black-light;
                border: 0.5px solid #9EADC7;
                padding: 0px 8px;
                cursor: pointer;

                &.active {
                    background-color: #A6E7C2;
                    border: 0.5px solid #A6E7C2;
                }
            }
        }
    }
}