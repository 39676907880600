@import './../../../styles/root.scss';
#modal-container {
    position: fixed;
    display: table;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: scale(0);
    z-index: $z3;

    &.active_modal {
        transform: scale(1);

        .modal-background {
            background: rgba(0, 0, 0, 0);
            animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

            .modal {
                opacity: 0;
                animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            }
        }

        +.content {
            animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }

        &.out {
            animation: quickScaleDown 0s 0.5s linear forwards;

            .modal-background {
                animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

                .modal {
                    animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
                }
            }

            +.content {
                animation: scaleForward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            }
        }
    }

    .modal-background {
        display: table-cell;
        background: rgba(0, 0, 0, 0.8);
        padding: 10%;

        .modal {
            background: white;
            padding: 10px;
            display: inline-block;
            border-radius: 3px;
            overflow: visible;
            position: relative;

            button {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                border: none;
                padding: 10px 14px;
                border-radius: 50%;
                font-size: 20px;
                font-weight: bold;
                background-color: $aqua;
                cursor: pointer;

                svg {
                    cursor: pointer;
                }
            }
        }
    }
}


@keyframes fadeIn {
    0% {
        background: rgba(0, 0, 0, 0);
    }

    100% {
        background: rgba(0, 0, 0, 0.7);
    }
}

@keyframes fadeOut {
    0% {
        background: rgba(0, 0, 0, 0.7);
    }

    100% {
        background: rgba(0, 0, 0, 0);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.8) translateY(1000px);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }

    100% {
        transform: scale(0.8) translateY(1000px);
        opacity: 0;
    }
}

@keyframes scaleBack {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.85);
    }
}

@keyframes scaleForward {
    0% {
        transform: scale(0.85);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes quickScaleDown {
    0% {
        transform: scale(1);
    }

    99.9% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}