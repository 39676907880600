@import './../../../styles/root.scss';

.course_details_section{
    padding: 120px 0px;

    .course_heading{
        margin-bottom: 50px;
        h2{
            @include heading1;
            font-size: 40px;
            font-weight: 400;
        }

        .class_completation_bar{
            width: 100%;
            height: 12px;
            border-radius: 40px;
            background-color: #D9D9D9;

            .bar_status{
                width: 20%;
                height: 12px;
                border-radius: 40px;
                background-color: $aqua-dark;
            }
        }
    }

    .course_type{
        padding: 24px 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        background-color: #4DF9CE;
        margin-bottom: 24px;
        
        .text_box{
            .tag{
                font-family: $mono;
                font-size: 12px;
                font-weight: 400;
                color: $black-light;
                background-color: $white;
                padding: 2px 8px;
                border-radius: 10px;
            }
            p{
                margin: 8px 0px 0px 0px;
                font-family: $mono;
                font-size: 16px;
                font-weight: 400;
                color: $black-light;
            }
        }
    }

    .course_chapters{
        h3{
            font-family: $corm;
            font-size: 24px;
            font-weight: 400;
            color: $black-light;
        }
        p{
            font-family: $mono;
            font-size: 14px;
            font-weight: 400;
            color: $dusk;
            margin-bottom: 16px;
        }

        .lessons{
            padding: 0px;
            li{
                padding: 24px 16px;
                display: flex;
                align-items: center;
                gap: 15px;
                opacity: 40%;
                border-radius: 16px;
                .icon_box{
                    position: relative;
                    .imgbox{
                        height: 48px;
                        width: 48px;
                        border-radius: 50%;
                        outline: 1px solid #D9D9D9;
                        outline-offset: 2px;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%, 25%);
                        width: 1px;
                        height: 32px;
                        background-color: #617A7A;
                    }
                }
                .text_box{
                    h3{
                        font-family: $mono;
                        font-size: 16px;
                        font-weight: 400;
                        color: $black-light;
                    }
                    h4{
                        font-family: $mono;
                        font-size: 14px;
                        font-weight: 400;
                        color: $dusk;
                    }
                }

                &:nth-child(1),
                &:last-child{
                    opacity: 100%;
                    background: #EEF7FB;
                }
                &:last-child{
                    .icon_box{
                        &::after{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}