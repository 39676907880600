@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    cursor: default;
}
body{
    margin: 0px;
    padding: 0px;
    background-color: #fff;
    color: #000;
    position: static;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
select,
input,
button:focus{
    outline: none;
}
ul,
ol{
    list-style: none;
    padding: 0;
    margin: 0;
}
a{
    text-decoration: none;
    color: #000;
}
a:hover{
    text-decoration: none;
    color: #000;
}

h1,h2,h3,h4,h5,h6,p,a,span{
    margin: 0;
    padding: 0;
    user-select: none;
}

.imgbox_full,
.imgbox {
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        user-select: none;
    }
}

.imgbox_full {
    img {
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
}