@import './../../../styles/root.scss';

.post_time {
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 32px;

    li {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: $corm;
        font-size: 20px;
        color: $black-light;

        i {
            color: $aqua;
        }
    }
}