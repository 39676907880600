@import './root.scss';

.cart_section {
    padding: 60px 0 0;

    .cart_items {
        padding: 0;
        margin: 0;
        background-color: $aqua-light;
        padding: 20px;
        border-radius: 5px;

        .items {
            margin-bottom: 20px;

            .img_content {
                .price_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;

                    h2 {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }

            .text_content {
                .subheading {
                    font-size: 16px;
                }

                .heading {
                    @include heading1;
                    font-size: 32px;
                }

                .description {
                    @include pera1;
                    font-size: 14px;
                }
            }
        }
    }

    .sign_up_form,
    .billing_form,
    .payment_form {
        padding: 50px 20px;

        .title {
            @include heading1;
            font-size: 32px;
        }

        form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            flex-wrap: wrap;

            .method_list {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;
                margin: 20px 0px;

                .method {
                    height: 60px;
                    width: 120px;
                    border: 1px solid #ddd;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.active {
                        border: 1px solid $aqua-dark;
                    }
                }
            }

            .input_box {
                margin: 10px 0px;
                width: calc(50% - 20px);

                label {
                    display: block;
                    font-family: $mono;
                    font-weight: 600;

                    span {
                        color: red;
                        margin-right: 5px;
                    }
                }

                input,
                select,
                .password_box {
                    width: 100%;
                    padding: 8px 12px;
                    border: none;
                    border-bottom: 1px solid #ddd;
                    background-color: transparent;
                }

                .password_box {
                    display: flex;
                    align-items: center;

                    input {
                        padding: 0px;
                        border: none;
                    }

                    i {
                        color: #000;
                        cursor: pointer;
                    }
                }

                &.full_width {
                    width: 100%;
                }

                .redeem {
                    @include button1;
                }
            }

            .have_account {
                width: 100%;
                margin: 30px 0px;

                a {
                    color: $aqua-dark;
                    cursor: pointer;
                }
            }

            .business {
                width: 100%;
                margin: 30px 0px;

                input{
                    margin-right: 5px;
                }
            }

            .agreement_one,
            .agreement_tow {
                width: 100%;
                margin: 0px;

                span {
                    color: red;
                }

                input{
                    margin-right: 5px;
                }

                a {
                    color: $aqua-dark;
                    cursor: pointer;
                }
            }
        }
    }

    .submit_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        margin-bottom: 100px;

        .total_price {
            @include heading1;
            font-size: 32px;
        }

        .buy {
            @include button1;
            width: 100%;
            max-width: 260px;
        }
    }
}