@import './../../../styles/root.scss';
.comment_review {
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: $white;
    border-radius: 10px;
    border: none;
    height: 100%;
    position: relative;

    .head {
        margin-bottom: 40px;

        .imgbox {
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }
    }

    .body {
        padding-bottom: 60px;
        p {
            @include pera1;
            text-align: left;
            user-select: none;
        }
    }

    .footer {
        background-color: $aqua-light;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        border-radius: 8px;
        position: absolute;
        width: calc(100% - 40px);
        left: 20px;
        bottom: 20px;

        .heading_box {
            h4 {
                font-family: $mono;
                font-size: 14px;
                font-weight: 700;
                color: $black-light;
                user-select: none;
            }

            h5 {
                font-family: $mono;
                font-size: 12px;
                font-weight: 400;
                color: $black-light;
                user-select: none;
            }
        }
    }
}