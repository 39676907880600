@import './../../../styles/root.scss';

.footer{
    padding: 60px 0px;
    background-color: $nile;

    .line{
        width: 100%;
        height: 1px;
        background-color: $aqua-light;
        margin: 20px 0px;
    }

    .about_box{
        .logo {
            font-family: $mono;
            font-size: 24px;
            font-weight: 700;
            color: $black;
            cursor: pointer;
        }
        p{
            color: $white-light;
            font-size: 15px;
            margin-bottom: 0px;
        }

        h3{
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 2px;
            margin: 20px 0px 10px;
        }

        .social{
            display: flex;
            align-items: center;
            gap: 8px;
            li{
                height: 50px;
                width: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg{
                    font-size: 25px;
                    color: $white;
                    cursor: pointer;
                }

                &.facebook{
                    background-color: #3b5998;
                }
                &.twiter{
                    background-color: #55acee;
                }
                &.google{
                    background-color: #dd4b39;
                }
            }
        }
    }

    .link_box{
        h2{
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 2px;
        }

        ul{
            a{
                li{
                    color: $white-light;
                    font-size: 15px;
                    transition: 0.3s;
                    cursor: pointer;

                    &:hover{
                        color: $aqua;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }

    .subscribe_box{
        h2{
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 2px;
        }
        p{
            color: $white-light;
            font-size: 15px;
            margin: 10px 0px;
        }
        .inputbox{
            position: relative;
            overflow: hidden;
            input{
                width: 100%;
                padding: 14px 28px;
                background: #2e2e2e;
                border: 1px solid #2e2e2e;
                color: #fff;
            }
            button{
                position: absolute;
                right: 0;
                background: $aqua;
                padding: 15px 20px;
                border: none;
                top: 0;
                cursor: pointer;
                i{
                    color: #fff;
                    font-size: 22px;
                    cursor: pointer;
                }
            }
        }
    }
}