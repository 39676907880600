@import './../../../styles/root.scss';
.testimonial_section {
    padding: 120px 0px;

    h3 {
        @include heading2;
        text-align: center;
        color: $black-light;
    }

    h2 {
        @include heading1;
        text-align: center;
    }

    p {
        @include pera1;
        text-align: center;
        margin-bottom: 40px;
    }

    .swiper_container {
        .swiper {
            background-color: transparent;
            padding: 20px 20px 50px 20px;

            .swiper-wrapper {
                background-color: transparent;

                .swiper-slide {
                    background-color: transparent;
                    height: auto;
                }
            }
        }
    }

}