@import './root.scss';

@media only screen and (max-width: 1367px) {}

@media only screen and (max-width: 1366px) {

     /* banner section start */
     .banner_section {
          padding: 0px;

          .banner_text {
               .text_box {
                    h2 {
                         font-size: 62px;
                    }

                    p {
                         font-size: 18px;
                    }
               }
          }
     }

     /* banner section end */
}

@media only screen and (max-width: 1280px) {

     /* banner section start */
     .banner_section {
          .banner_text {
               .text_box {
                    h2 {
                         font-size: 60px;
                    }

                    p {
                         font-size: 20px;
                    }
               }
          }
     }

     /* banner section end */

     /* testimonial section start */
     .testimonial_section {
          .swiper_container {
               .swiper {
                    padding: 10px 10px 50px 10px;

                    .swiper-wrapper {
                         .swiper-slide {
                              .comment_review {
                                   padding: 10px;

                                   .head {
                                        margin-bottom: 20px;
                                   }

                                   .body {
                                        p {
                                             font-size: 14px;
                                        }
                                   }

                                   .footer {
                                        padding: 12px;

                                        .ratings {
                                             li {
                                                  font-size: 10px;
                                             }
                                        }
                                   }
                              }

                              .video_review {
                                   padding: 10px;

                                   .head {
                                        margin-bottom: 20px;
                                   }

                                   .body {
                                        p {
                                             font-size: 16px;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }

     /* testimonial section end */
}

@media only screen and (max-width: 1024px) {

     /* banner section start */
     .banner_section {
          .banner_text {
               .text_box {
                    h2 {
                         font-size: 50px;
                    }

                    p {
                         font-size: 18px;
                    }
               }
          }
     }

     /* banner section end */

     /* about section start */
     .about_section {
          .about_img {
               padding: 20px;
          }

          .about_text {
               h2 {
                    font-size: 35px;
               }

               p {
                    font-size: 16px;
               }
          }
     }

     /* about section end */

     /* testimonial section start */
     .testimonial_section {
          .swiper_container {
               .swiper {
                    .swiper-wrapper {
                         .swiper-slide {
                              .comment_review {
                                   .footer {
                                        flex-direction: column;
                                        align-items: flex-start;
                                   }
                              }
                         }
                    }
               }
          }
     }

     /* testimonial section end */

     /* meet section start */
     .meet_section {
          .meet_img {
               .imgbox {
                    &.img1 {
                         height: 70px;
                         width: 70px;
                    }

                    &.img2 {
                         height: 200px;
                         width: 200px;
                    }

                    &.img3 {
                         height: 150px;
                         width: 150px;
                    }

                    &.img4 {
                         height: 100px;
                         width: 100px;
                    }

                    &.img5 {
                         height: 70px;
                         width: 70px;
                    }
               }

               .community_text {
                    height: 250px;
                    width: 250px;
               }
          }
     }

     /* meet section end */
}

@media only screen and (max-width: 912px) {

     /* banner section start */
     .banner_section {
          .banner_text {
               .text_box {
                    textarea {
                         margin-top: 20px;
                    }
               }

               button {
                    margin-top: 10px;
               }
          }
     }

     /* banner section end */

     /* course section start */
     .course_section {
          .swiper_container {
               .swiper {
                    padding: 10px 10px 50px 10px;

                    .swiper-wrapper {
                         .swiper-slide {
                              .card {
                                   .head {
                                        .tag_list {
                                             li {
                                                  padding: 7px 10px;
                                             }
                                        }
                                   }

                                   .body {
                                        .tag_list {
                                             li {
                                                  gap: 2px;
                                                  padding: 6px 5px;
                                                  border-radius: 40px;
                                                  font-size: 12px;

                                                  .imgbox {
                                                       height: 20px;
                                                       width: 20px;
                                                  }
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }

     /* course section end */
}

@media only screen and (max-width: 820px) {

     /* banner section start */
     .banner_section {
          .banner_text {
               .text_box {
                    h2 {
                         font-size: 40px;
                    }

                    p {
                         font-size: 16px;
                    }

                    textarea {
                         margin-top: 10px;
                    }
               }
          }
     }

     /* banner section end */
}

@media only screen and (max-width: 768px) {

     /* banner section start */
     .banner_section {
          .banner_text {
               padding: 20px;
          }
     }

     /* banner section end */
}

@media only screen and (max-width: 540px) {

     /* fill section start*/
     .fill_section {
          .fill_text {
               h2 {
                    font-size: 35px;
               }

               p {
                    font-size: 16px;
               }
          }

          .fill_img {
               padding: 10px;
          }
     }

     /* fill section end*/

     /* course section start */
     .course_section {
          .heading_box {
               flex-direction: column;

               h2 {
                    font-size: 35px;
               }
          }

          .swiper_container {
               .swiper {
                    padding: 10px 10px 50px 10px;

                    .swiper-wrapper {
                         .swiper-slide {
                              .card {
                                   .head {
                                        .tag_list {
                                             gap: 5px;
                                             padding: 5px;

                                             li {
                                                  gap: 2px;
                                                  padding: 3px 5px;
                                                  font-size: 10px;
                                             }
                                        }
                                   }

                                   .body {
                                        padding: 5px;

                                        .tag_list {
                                             gap: 5px;

                                             li {
                                                  gap: 2px;
                                                  padding: 5px;
                                                  font-size: 10px;

                                                  &.bg_aqua {
                                                       background-color: $aqua;
                                                  }

                                                  &.img_tag {
                                                       padding: 5px;
                                                  }
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }

     /* course section end */
}

@media only screen and (max-width: 414px) {
     /* meet section start */
     .meet_section {
          .meet_img {
               height: 400px;
               .imgbox {
                    &.img1 {
                         height: 50px;
                         width: 50px;
                    }

                    &.img2 {
                         height: 100px;
                         width: 100px;
                    }

                    &.img3 {
                         height: 80px;
                         width: 80px;
                    }

                    &.img4 {
                         height: 60px;
                         width: 60px;
                    }

                    &.img5 {
                         height: 50px;
                         width: 50px;
                    }
               }

               .community_text {
                    height: 200px;
                    width: 200px;
               }
          }
     }

     /* meet section end */
}

@media only screen and (max-width: 412px) {}

@media only screen and (max-width: 393px) {}

@media only screen and (max-width: 390px) {}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 360px) {}

@media only screen and (max-width: 280px) {}