@import './../../../styles/root.scss';

.steps_card{
    padding: 32px 24px;
    border: none;
    box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.08);

    .head{
        margin-bottom: 24px;
        .icon_box{
            height: 60px;
            width: 60px;     
            font-size: 32px;
            background-color: #E1FEF7;
            color: #4DAC9D;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .body{
        h3{
            font-family: $mono;
            font-size: 20px;
            font-weight: 700;
            color: $black-light;
        }
        p{
            font-family: $mono;
            font-size: 16px;
            color: $dusk;
        }
    }
}