@import './../../../styles/root.scss';
.more_list_item{
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    .icon_box{
        svg{
            font-size: 40px;
            fill: #040505;
        }
    }

    .text_box {
        h3 {
            font-family: $mono;
            font-size: 20px;
            font-weight: 700;
            color: $black-light;
        }

        p {
            @include pera1;
            font-size: 16px;
        }
    }
}