@import './../../../styles/root.scss';

.section_navbar {
    width: 100%;
    height: 70px;
    background-color: $aqua-light;
    
    .container-xl{
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        font-family: $mono;
        font-size: 24px;
        font-weight: 700;
        color: $black;
        cursor: pointer;
    }

    .hamburger {
        font-size: 40px;
        font-weight: bold;
        color: $black;
        align-items: center;
        min-height: 70px;
        display: none;
    }

    .nav_menu {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 0;
        margin: 0;
        z-index: $z1;

        li {
            color: $black;
            font-size: 16px;
            font-family: $mono;
            font-weight: 500;
            transition: t(0.3);
            cursor: pointer;
            position: relative;

            span{
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            a {
                color: $black;
                font-size: 16px;
                font-family: $mono;
                font-weight: 500;
                transition: t(0.3);
                cursor: pointer;
            }

            svg {
                cursor: pointer;
                font-size: 15px;
                margin-left: 5px;
            }

            &:hover {
                color: $aqua;

                a {
                    color: $aqua;
                }

                .dropdown {
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                    li {
                        a {
                            color: $black;
                        }
                    }
                }
            }

            .dropdown {
                background-color: $aqua-light;
                padding: 20px;
                position: absolute;
                left: 50%;
                top: 80%;
                transform: translateX(-50%);
                min-width: 150px;
                border-radius: 10px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                opacity: 0;
                visibility: hidden;
                transition: t(0.3);

                li {
                    margin-bottom: 14px;

                    &:hover {
                        color: $aqua;

                        a {
                            color: $aqua;
                        }
                    }
                }
            }
        }
    }

    .register_btn_sm,
    .register_btn_lg{
        @include button1;
        &:hover{
            transform: translate(0%);
        }
    }
    .register_btn_sm{
        display: none;
    }

    .active {
        top: 70px !important;
    }
}

@media only screen and (max-width: 912px) {
    .section_navbar {
        .container-xl{
            padding: 0px;
        }
        .nav_menu {
            position: fixed;
            top: -100%;
            background-color: $aqua-light;
            width: 100%;
            right: 0;
            display: block !important;
            box-sizing: border-box;
            padding: 20px;
            transition: all 0.5s ease-in-out;

            li {
                margin-bottom: 10px;
                &:hover {
                    .dropdown {
                        opacity: 1;
                        visibility: visible;
                        height: auto;
                        padding: 0px 10px;
                    }
                }
    
                .dropdown {
                    background-color: transparent;
                    position: static;
                    transform: translateX(0%);
                    box-shadow: none;
                    opacity: 0;
                    visibility: hidden;
                    height: 0px;
                    padding: 0;
                }
            }
        }
        .register_btn_sm{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            &:hover{
                color: $white !important;
            }
        }
        .register_btn_lg{
            display: none;
        }

        .hamburger {
            display: flex !important;
        }
    }
}