@import './../../../styles/root.scss';

.blog_wrapper {
    position: relative;
    min-height: 100vh;

    .right_menu {
        position: absolute;
        right: 0;
        top: 0;
        width: 400px;
        height: 100%;
        background-color: $aqua-light;
        transition: t(0.3);
        z-index: $z2;

        .menu_controler {
            position: absolute;
            left: 0;
            top: 24px;
            transform: translateX(-100%);
            border: none;
            padding: 9px 5px;
            background-color: $aqua;
            border-radius: 8px 0px 0px 8px;
            font-size: 20px;
            cursor: pointer;

            .bx-search {
                display: none;
                transition: t(0.3);
                cursor: pointer;
            }

            .bx-chevron-right {
                display: block;
                transition: t(0.3);
                cursor: pointer;
            }
        }

        .right_menu_content {
            overflow-y: scroll;
            height: 100%;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .blog_content {
        padding-right: 400px;
        transition: t(0.3);
    }
}

.menu_status {
    .right_menu {
        right: -400px;

        .menu_controler {
            .bx-search {
                display: block;
            }

            .bx-chevron-right {
                display: none;
            }
        }
    }

    .blog_content {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 912px) {
    .blog_wrapper {
        .right_menu {
            right: -400px;

            .menu_controler {
                .bx-search {
                    display: block;
                }

                .bx-chevron-right {
                    display: none;
                }
            }
        }

        .blog_content {
            padding-right: 0px;

            .blogs {
                background-color: green;
            }
        }
    }

    .menu_status {
        .right_menu {
            right: 0px;

            .menu_controler {
                .bx-search {
                    display: none;
                }

                .bx-chevron-right {
                    display: block;
                }
            }
        }

        .blog_content {
            padding-right: -400px;
        }
    }
}

@media only screen and (max-width: 414px) {

    .blog_wrapper {
        .right_menu {
            width: 250px;
            right: -250px;
        }
    }

    .menu_status {
        .right_menu {
            right: 0px;
        }

        .blog_content {
            padding-right: -250px;
        }
    }
}