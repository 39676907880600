@import './../../../styles/root.scss';
.meet_section{
    padding: 120px 0px;

    .meet_text{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h2{
            @include heading1;
            margin-bottom: 40px;
        }
        a{
            @include button1;
            margin-bottom: 20px;
        }
    }

    .meet_img{
        height: 600px;
        overflow: hidden;
        position: relative;

        .imgbox{
            position: absolute;
            
            border-radius: 50%;

            &.img1{
                height: 100px;
                width: 100px;
                top: 0;
                left: 10%;
            }
            &.img2{
                height: 250px;
                width: 250px;
                top: 0;
                right: 5%;
            }
            &.img3{
                height: 200px;
                width: 200px;
                top: 40%;
                left: 0;
            }
            &.img4{
                height: 150px;
                width: 150px;
                top: 50%;
                right: 0;
            }
            &.img5{
                height: 100px;
                width: 100px;
                bottom: 0;
                right: 10%;
            }
        }

        .community_text{
            position: absolute;
            height: 300px;
            width: 300px;
            border-radius: 50%;
            background-color: $aqua;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            h3{
               font-family: $mono;
               font-size: 80px;
               font-weight: 700; 
            }
            p{
                font-family: $mono;
               font-size: 14px;
               font-weight: 400;
            }
        }
    }
}