@import './../../../styles/root.scss';

.course_banner_section {
    .imgbox {
        height: 200px;
    }
}

.course_details_main_section {
    .course_title {
        @include heading1;
        margin: 100px 0px 20px 0px;
    }

    .logo_with_list_box {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 40px 0px;

        .logo {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            outline: 2px solid $aqua;
            outline-offset: 2px;
            overflow: hidden;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            li {
                display: flex;
                flex-direction: column;
                @include pera2;
                padding-right: 20px;
                border-right: 2px solid #00000050;

                .bold {
                    color: #000;
                    font-weight: 600;
                }

                &:nth-last-child(1) {
                    border-right: none;
                }
            }
        }
    }

    .tab_trigger_box {
        margin-bottom: 30px;

        .tab_trigger {
            border: 1px solid #b3b3b3;
            padding: 8px 30px;
            font-family: $mono;
            font-size: 18px;
            font-weight: 500;
            color: #000;
            background-color: #bfbfbf20;
            margin-right: 10px;
            margin-bottom: 10px;

            &.active {
                color: $aqua-dark;
            }
        }
    }

    .tab_data_box {
        .tab_data {
            display: none;
            opacity: 0;
            transition: opacity .15s linear;

            &.active {
                display: block;
                opacity: 1;
            }
        }
    }

    .descriptions {
        .heading {
            font-family: $mono;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .pera {
            @include pera2;
            margin-bottom: 20px;
        }

        .targets {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                width: calc(100% / 2 - 20px);
                margin-bottom: 20px;
                @include pera2;

                svg {
                    fill: $aqua-dark;
                }
            }
        }
    }

    .curriculum {
        .pera {
            @include pera1;
            margin-bottom: 20px;
        }

        .accordion_item {
            border: 1px solid #dddddd;
            padding: 10px;

            .head {
                .title {
                    font-size: 18px;
                }
            }

            .body {
                .list {
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 10px;

                        .left_box {
                            .title {
                                font-size: 15px;

                                span {
                                    opacity: 0.6;
                                    margin-right: 5px;
                                }

                                svg {
                                    fill: $aqua-dark;
                                    line-height: 1;
                                    margin: 0px 5px 0px 0px;
                                }
                            }
                        }

                        .right_box {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            button {
                                padding: 2px 8px;
                                font-size: 14px;
                                border: none;
                                background-color: $aqua;
                                border-radius: 5px;
                            }

                            .duration {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .instructor {
        .title {
            font-family: $mono;
            font-size: 22px;
            font-weight: 500;
        }

        .name {
            font-family: $mono;
            font-size: 22px;
            font-weight: 500;
            color: $aqua-dark;
            text-decoration: underline;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .subname {
            font-size: 16px;
            opacity: 0.6;
            margin-bottom: 20px;
        }

        .profile_box {
            display: flex;
            align-items: center;
            gap: 10px;

            .imgbox {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                flex-shrink: 0;
            }

            .list {
                li {
                    font-size: 14px;
                    opacity: 0.6;
                    display: flex;
                    align-items: center;

                    svg {
                        fill: $aqua-dark;
                        opacity: 1;
                        line-height: 1;
                        margin-right: 5px;
                    }
                }
            }
        }

        .pera {
            @include pera2;
            margin: 20px 0px;
        }

        .show_more {
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;
            color: $aqua-dark;
        }
    }

    .reviews {
        .rating_content {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            padding-bottom: 20px;
            border-bottom: 1px solid #dddddd;

            .rating_square {
                padding: 40px;
                border: 1px solid #dddddd;
                height: 100%;
                text-align: center;

                .rating_text {
                    font-size: 62px;
                    font-weight: 600;
                }

                .starts {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 3px;

                    li {
                        i {
                            color: $aqua-dark;
                        }
                    }
                }

                .pera {
                    font-size: 16px;
                    margin: 0;
                }
            }

            .rating_bars_box{
                flex-grow: 5;
                .rating_bar{
                    margin-bottom: 5px;
                    .top_box{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        p{
                            font-size: 16px;
                            margin: 0 0 2px 0;
                        }
                    }
                    .bar_outer{
                        width: 100%;
                        height: 10px;
                        border-radius: 3px;
                        background-color: $white-light;

                        .bar_inner{
                            border-radius: 3px;
                            height: 100%;
                            background-color: $aqua-dark;
                        }
                    }
                }
            }
        }

        .reviewer_content{
            padding: 20px 0px;
            .profile_box{
                display: flex;
                gap: 20px;
                padding-bottom: 10px;
                margin-bottom: 20px;
                border-bottom: 1px solid #dddddd;

                .imgbox{
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    outline: 2px solid #dddddd;
                    outline-offset: 2px;
                    flex-shrink: 0;
                }

                .text_box{
                    .title_and_time{
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .title{
                            font-size: 24px;
                            font-weight: 600;
                            margin: 0;
                        }
                        .time{
                            margin: 0;
                            font-size: 16px;
                            color: $dusk;
                            padding-left: 10px;
                            border-left: 1px solid #dddddd;
                        }
                    }

                    .starts {
                        display: flex;
                        align-items: center;
                        gap: 3px;
    
                        li {
                            i {
                                color: $aqua-dark;
                            }
                        }
                    }

                    .pera{
                        @include pera2;
                        font-size: 14px;
                    }
                }

                &:nth-last-child(1){
                    border-bottom: none;
                }
            }
        }
    }

    .card.course_description_card {
        border: none;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin: -100px 0px 40px 0px;

        .head {
            .imgbox {
                position: relative;

                .play_button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 60px;
                    width: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    color: $aqua-dark;
                    background-color: $white;
                    border-radius: 50%;
                    border: none;
                    outline: 5px solid #ffffff50;

                }
            }

            .price_box {
                background-color: #b3b3b360;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .price {
                    font-family: $mono;
                    font-size: 32px;
                    font-weight: 600;
                }

                .ratingbox {
                    display: flex;
                    gap: 5px;

                    .ratings {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        li {
                            font-family: $mono;
                            font-size: 14px;
                        }

                        i {
                            color: $aqua-dark;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }

        .body {
            padding: 10px 20px;

            .course_details_list {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include pera2;
                    font-weight: 500;
                    font-size: 16px;
                    padding: 10px 0px;
                    border-bottom: 1px solid #00000050;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }

                    i {
                        color: $aqua-dark;
                    }

                    &:nth-last-child(1) {
                        border-bottom: none;
                    }
                }
            }

            .enroll_button {
                @include button1;
                width: 100%;
                margin: 20px 0px;
            }
        }
    }

    .categories_box {
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin-bottom: 50px;

        .title {
            font-family: $mono;
            font-size: 18px;
            font-weight: 500;
        }

        .category_list {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 10px 0px;
                font-size: 16px;
                font-family: $mono;
                font-weight: 500;
                color: #00000080;

                .bold {
                    color: #000;
                }
            }
        }
    }

    .related_courses {
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin-bottom: 50px;

        .title {
            font-family: $mono;
            font-size: 18px;
            font-weight: 500;
        }

        .course_list {
            padding: 0;

            .course {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                margin-bottom: 20px;

                .imgbox {
                    height: 100px;
                    width: 100px;
                    flex-shrink: 0;
                }

                .text_box {
                    .course_title {
                        font-size: 16px;
                        font-weight: 600;
                        margin: 0px 0px 10px 0px;
                        font-family: $mono;
                    }

                    .ratingbox {
                        display: flex;
                        gap: 5px;
                        margin-bottom: 10px;

                        .ratings {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            li {
                                font-family: $mono;
                                font-size: 14px;
                            }

                            i {
                                color: $aqua-dark;
                                margin-top: 4px;
                            }
                        }
                    }

                    .aurthor {
                        font-size: 14px;
                        font-weight: 600;
                        font-family: $mono;

                        i,
                        span {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }


}

@media only screen and (max-width: 540px) {

    .course_details_main_section {
        .logo_with_list_box {
            .list {
                li {
                    font-size: 14px;
                    padding-right: 10px;
                }
            }
        }

        .descriptions {
            .targets {
                li {
                    width: 100%;
                }
            }
        }

        .curriculum {
            .accordion_item {
                .body {
                    .list {
                        li {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }

        .card.course_description_card {
            margin: 40px 0px 40px 0px;
        }
    }
}

@media only screen and (max-width: 420px) {

    .course_details_main_section {
        .logo_with_list_box {
            flex-direction: column;
            align-items: flex-start;

            .list {
                flex-direction: column;

                li {
                    border-right: none;
                }
            }
        }

        .descriptions {
            .targets {
                li {
                    width: 100%;
                }
            }
        }

        .reviews {
            .rating_content {
                flex-direction: column;
                align-items: center;
                .rating_square{
                    width: 100%;
                }
                .rating_bars_box{
                    width: 100%;
                }
            }
    
            .reviewer_content{
                .profile_box{
                    flex-direction: column;
                    align-items: center;
    
                    .text_box{
                        .title_and_time{
                            flex-direction: column;
                            .time{
                                border-left: none;
                            }
                        }
    
                        .starts {
                            justify-content: center;
                        }
    
                        .pera{
                            text-align: center;
                        }
                    }
                }
            }
        }



        .related_courses {
            .course_list {
                .course {
                    flex-direction: column;
                }
            }
        }
    }
}