@import './../../../styles/root.scss';

.class_selector{
    font-family: $corm;
    font-size: 24px;
    font-weight: 400;
    color: $black-light;
    border: none;
    background-color: transparent;
    margin-bottom: 40px;
}