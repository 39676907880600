@import './root.scss';

.events_section {
    padding: 160px 0px;

    .heading {
        text-align: center;
        font-size: 32px;
        font-weight: 200;
        color: $black;

        span {
            color: $aqua;
            font-weight: 700;
        }
    }

    .devider_box {
        display: flex;
        align-items: center;
        justify-content: center;

        .devider {
            font-size: 18px;
            color: #00000050;
            position: relative;
            padding: 10px 20px;
            display: inline-block;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                height: 2px;
                width: 80px;
                background-color: #00000050;
            }

            &::before {
                left: 0%;
                transform: translate(-100%, -50%);
            }

            &::after {
                right: 0%;
                transform: translate(100%, -50%);
            }

        }
    }

    .pera {
        text-align: center;
        font-size: 14px;
        color: #00000050;
        margin-bottom: 40px;
    }

    .card.event_card {
        border: none;
        background-color: transparent;

        .head {
            border-radius: 10px;
            overflow: hidden;
        }

        .body {
            padding: 20px;
            margin: -40px 20px 0px 20px;
            background-color: $white;
            border-radius: 10px;
            border-top: 4px solid $aqua;

            .heading {
                font-size: 18px;
                font-weight: 600;
                color: $black;
                margin-bottom: 20px;
            }

            .tag_box {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 20px;

                .tag {
                    background-color: $aqua;
                    padding: 8px 20px;
                    border-radius: 20px;
                    font-size: 14px;
                    font-weight: 500;
                }

                .date {
                    font-size: 14px;
                    color: #00000050;
                }
            }

            .pera {
                font-size: 14px;
                color: #00000050;
            }

            .icon_with_text {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                align-items: center;

                li {
                    font-size: 14px;
                    color: #00000050;
                }
            }
        }
    }
}

.event_list_section {
    padding: 90px 0px;

    .button_box {
        .button_wrapper {
            height: 200px;
            max-width: 100px;
            margin: 0 0 0 auto;

            .button {
                width: 200px;
                border: none;
                outline: none;
                transform: rotate(-90deg) translate(-65px, -45px);

                span {
                    display: block;
                    text-align: center;
                    color: $white;
                }

                .title {
                    font-size: 22px;
                    font-weight: 700;
                }

                .date {
                    @include pera1;
                    font-size: 14px;
                }

                &.active {
                    .title {
                        color: $aqua-dark;
                    }
                }
            }
        }
    }

    .event_accordion {
        border-radius: 15px;
        margin-bottom: 30px;
        padding: 20px;
        background-color: $white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .head {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 30px;

            .imgbox {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                outline: 2px solid $aqua-dark;
                outline-offset: 3px;
                flex-shrink: 0;
                margin-right: 20px;
            }

            .text_box {
                .time {
                    background-color: $aqua-light;
                    display: inline-block;
                    padding: 5px;
                    border-radius: 5px;

                    h3 {
                        font-size: 14px;
                        line-height: 1;
                        margin: 0;
                        font-weight: 600;

                        span {
                            color: $aqua;
                        }
                    }
                }

                .title {
                    font-size: 24px;
                    font-weight: 700;
                    margin: 10px 0px 0px;
                }

                .aurthor {
                    font-size: 16px;

                    span {
                        color: $aqua-dark;
                    }
                }
            }
        }

        .body {
            .pera {
                @include pera1;
                font-size: 16px;
            }

            .location {
                font-size: 18px;

                span {
                    color: $aqua-dark;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .event_list_section {
        .button_box {
            display: flex;
            flex-wrap: wrap;

            .button_wrapper {
                height: auto;
                max-width: 50%;
                width: 50%;
                margin: 0;

                .button {
                    width: auto;
                    padding: 20px;
                    transform: rotate(0deg) translate(0px, 0px);
                }
            }
        }

        .event_accordion {
            .head {
                display: flex;
                flex-direction: column;

                .imgbox {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    outline: 2px solid $aqua-dark;
                    outline-offset: 3px;
                    flex-shrink: 0;
                }

                .text_box {
                    .time {
                        background-color: $aqua-light;
                        display: inline-block;
                        padding: 5px;
                        border-radius: 5px;

                        h3 {
                            font-size: 14px;
                            line-height: 1;
                            margin: 0;
                            font-weight: 600;

                            span {
                                color: $aqua;
                            }
                        }
                    }

                    .title {
                        font-size: 24px;
                        font-weight: 700;
                        margin: 10px 0px 0px;
                    }

                    .aurthor {
                        font-size: 16px;

                        span {
                            color: $aqua-dark;
                        }
                    }
                }
            }

            .body {
                .pera {
                    @include pera1;
                    font-size: 16px;
                }

                .location {
                    font-size: 18px;

                    span {
                        color: $aqua-dark;
                    }
                }
            }
        }
    }
}