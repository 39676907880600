@import './../../../styles/root.scss';

.related_courses {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    margin-bottom: 50px;

    .title {
        font-family: $mono;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .course_list {
        padding: 0;

        .course {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            margin-bottom: 20px;

            .imgbox {
                height: 100px;
                width: 100px;
                flex-shrink: 0;
            }

            .text_box {
                .course_title {
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0px 0px 10px 0px;
                    font-family: $mono;
                }

                .ratingbox {
                    display: flex;
                    gap: 5px;
                    margin-bottom: 10px;

                    .ratings {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        li {
                            font-family: $mono;
                            font-size: 14px;
                        }

                        i {
                            color: $aqua-dark;
                            margin-top: 4px;
                        }
                    }
                }

                .aurthor {
                    font-size: 14px;
                    font-weight: 600;
                    font-family: $mono;

                    i,
                    span {
                        opacity: 0.6;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 420px) {

    .course_details_main_section {
        .related_courses {
            .course_list {
                .course {
                    flex-direction: column;
                }
            }
        }
    }
}