@import './../../../styles/root.scss';

.faq_section {
    padding: 120px 0px;

    h3 {
        @include heading2;
        text-align: center;
        margin-bottom: 20px;
        color: $aqua;
    }

    h4 {
        @include heading1;
        text-align: center;
    }

    p {
        @include pera1;
        text-align: center;
        margin-bottom: 40px;
    }
}