@import './root.scss';

.subscription_section{
    padding: 160px 0px;

    .heading{
        @include heading1;
        text-align: center;
    }
    .pera{
        @include pera1;
        text-align: center;
        margin-bottom: 50px;
    }

    .card.subscription_card{
        border: none;
        border-radius: 10px;
        background-color: $white;
        overflow: hidden;
        box-shadow: #7AFADA 0px 1px 4px;
        margin-top: 20px;
        transition: all linear 0.3s;

        .body{
            padding: 20px;
            h2{
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 30px;
                font-family: $mono;
            }
            h3{
                font-size: 24px;
                font-weight: 400;
                font-family: $mono;
                span{
                    font-size: 32px;
                    font-weight: 500;
                }
            }
            p{
                font-size: 14px;
                color: #00000050;
                margin: 20px 0px;
            }

            .menu{
                display: block;
                li{
                    font-size: 14px;
                    color: #00000050;
                    margin-bottom: 5px;
                }
                span{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    svg{
                        fill: $aqua;
                    }
                    color: #000000;
                }

                .submenu{
                    padding-left: 40px;
                    list-style: disc;
                }
            }
        }

        .card_footer{
            padding: 20px;
            button{
                @include button1;
                width: 100%;
            }
        }

        &:hover{
            margin-top: 0;
            box-shadow: #7AFADA 0px 5px 15px;
        }
    }
}