@import './../../../styles/root.scss';

.banner_section{
    padding: 0px 124px;
    background-color: #fff;
    .banner_text{
        padding-left: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: $nile;
        .text_box{
            h2{
                font-family: $corm;
                font-size: 50px;
                font-weight: 700;
                color: $white;
            }
            p{
                font-family: $mono;
                font-size: 16px;
                font-weight: 400;
                color: $white;
                margin-bottom: 20px;
            }
            textarea{
                margin-top: 40px;
                width: 100%;
                height: 148px;
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, 0.40);
                outline: none;
                background-color: $dusk;
                padding: 10px;
            }
        }
    }
    .imgbox{
        height: 100%;
        img{
            height: 100%;
        }
    }
}