@import './root.scss';

.contact_section{
    padding: 160px 0px;

    .heading_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;

        .heading{
            @include heading1;
        }

        .pera{
            @include pera2;
            max-width: 600px;
        }
    }
    .title_box{
        position: relative;
        margin-bottom: 50px;

        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            height: 0.1px;
            width: calc(100% - 30px);
            background-color: #000000;
            z-index: -1;
        }

        .heading{
            margin: 0;
            background-color: #fff;
            display: inline-block;
            z-index: 1;
            padding: 0px 20px 0px 0px;
            font-size: 22px;
            font-weight: 600;
            color: $aqua;
        }
    }
    .contact_box{
        display: block;
        .heading{
            font-size: 28px;
            font-weight: 600;
        }
        .pera{
            @include pera2;
            margin-bottom: 20px;
        }
    }
    .socials{
        display: flex;
        align-items: center;
        gap: 10px;

        li{
            a{
                color: #000;
                font-size: 18px;
                transition: all linear 0.3s;
                cursor: pointer;

                svg,path{
                    cursor: pointer;
                }

                &:hover{
                    color: $aqua;
                }
            }
        }
    }

    form{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .pera{
            @include pera2;
            margin-bottom: 60px;
        }
        .input_box{
            width: 100%;
            padding: 10px 5px;
            border: none;
            border-bottom: 1px solid #000000;
            margin-bottom: 10px;
            outline: none;
        }
        .input_box.half_width{
            width: calc(50% - 20px);
        }
        textarea.input_box{
            height: 80px;
        }
        button{
            @include button1;
            margin-top: 30px;
        }
    }
}