@import './../../../styles/root.scss';

.more_section {
    padding: 120px 0px;

    h2 {
        @include heading1;
        font-weight: 500;

        span {
            color: $black-light;
            font-weight: 700;
        }
    }
}