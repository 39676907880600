@import './../../../styles/root.scss';

.course_section {
    padding: 120px 0px;
    background-color: $aqua-light;

    .heading_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        row-gap: 20px;
        margin-bottom: 60px;

        h2 {
            @include heading3;
        }
    }

    .swiper_container {
        .swiper {
            padding: 20px 20px 50px 20px;
            .swiper-pagination{
                .swiper-pagination-bullet-active{
                    background-color: $aqua;
                }
            }
        }
    }
}