@import './../../../styles/root.scss';

.blog_section{
    padding: 120px 0px;
}

@media (min-width: 768px){
    .blog_section{
        width: calc(100% - 16.67%);
        margin: 0 auto;
    }
}