@import './../../../styles/root.scss';
.video_review {
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: $white;
    border-radius: 10px;
    border: none;
    height: 100%;
    position: relative;

    .head {
        margin-bottom: 40px;
        position: relative;

        .imgbox {
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        }

        .play_button_box{
            background-color: #00000050;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 20px;
            .video_play_button {
                position: absolute;
                z-index: $z2;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                box-sizing: content-box;
                display: block;
                width: 12px;
                height: 24px;
                border-radius: 50%;
                padding: 18px 20px 18px 18px;
                border: none;
                cursor: pointer;
                background-color: transparent;

                img {
                    position: relative;
                    z-index: 3;
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    cursor: pointer;
                }
                span {
                    display: block;
                    position: relative;
                    z-index: 3;
                    width: 0;
                    height: 0;
                    border-left: 22px solid #fff;
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    cursor: pointer;
                }

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 50px;
                    height: 50px;
                    background: $aqua;
                    border-radius: 50%;
                    animation: pulseBorder 1500ms ease-out infinite;
                }

                &:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 50px;
                    height: 50px;
                    background: $aqua;
                    border-radius: 50%;
                    transition: all 200ms;
                    cursor: pointer;
                }

                &:hover{
                    &:after {
                        background-color: darken($aqua, 10%);
                    }
                }
            } 
        }                    
    }

    .body {
        h4 {
            font-family: $mono;
            font-size: 24px;
            font-weight: 700;
            color: $black-light;
            user-select: none;
        }

        h5 {
            font-family: $mono;
            font-size: 16px;
            font-weight: 400;
            color: $black-light;
            margin-bottom: 30px;
            user-select: none;
        }
        p {
            @include pera1;
            text-align: left;
            user-select: none;
        }
    }
}

@keyframes pulseBorder {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}