@import './../../../styles/root.scss';
.recent_posts_item{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;

    .imgbox {
        height: 60px;
        width: 60px;
        border-radius: 60px;
    }

    p {
        font-family: $mono;
        color: $black-light;
        font-size: 18px;
        flex-shrink: 20;
    }
}