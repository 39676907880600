@import './root.scss';

.blog_details_section {
    padding: 120px 0px;

    .left_content {

        h2 {
            font-family: $corm;
            font-size: 40px;
            color: $black-light;
            margin-bottom: 16px;
        }

        p {
            @include pera1;
            font-size: 16px;
        }

        .annotation {
            background: #F5FFFC;
            box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.08);
            border-left: 10px solid #A6E7C2;
            padding: 12px 48px 48px 12px;
            margin: 32px 0px;

            .imgbox {
                margin-bottom: 12px;
            }

            p {
                font-family: $mono;
                font-size: 24px;
                color: #154C2D;
                margin-left: 36px;
                margin-bottom: 0px;
            }
        }

        .targets {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: $mono;
                font-size: 15px;
                color: $dusk;
                margin-bottom: 16px;

                i {
                    font-size: 20px;
                    color: $aqua;
                }
            }
        }

        .tag_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            border: 1px solid #D9D9D9;
            border-left: none;
            border-right: none;
            margin: 30px 0px;
            padding: 40px 0px;

            .tag_box {
                display: flex;
                align-items: center;
                gap: 16px;

                h3 {
                    font-family: $mono;
                    font-size: 24px;
                    color: $black-light;
                    margin-top: -12px;
                }

                .category {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    padding: 0;

                    li {
                        padding: 12px 16px;
                        background-color: #EBFFF9;
                        font-family: $corm;
                        font-size: 16px;
                        font-weight: 500;
                        color: #227D49;
                        border-radius: 8px;
                    }
                }

                .social {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 24px;
                    color: $aqua;

                    li {
                        a {
                            color: $aqua;

                            i {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .review_box {
            display: flex;
            gap: 14px;

            .imgbox {
                height: 70px;
                width: 70px;
            }

            .review_text_box {
                h4 {
                    font-family: $mono;
                    font-size: 12px;
                    font-weight: 700;
                    color: $dusk;
                }

                h3 {
                    font-family: $mono;
                    color: $black-light;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }

    .right_content{
        .trainor_box{
            display: flex;
            align-items: center;
            gap: 14px;
            padding: 18px 24px;
            background: #F5FFFC;
            box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.08);
            margin-bottom: 32px;

            .imgbox{
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }

            .trainor_details{
                h4{
                    font-family: $mono;
                    font-size: 12px;
                    font-weight: 700;
                    color: $aqua;
                }
                h3{
                    font-family: $mono;
                    font-size: 18px;
                    font-weight: 700;
                    color: $black-light;
                }
                p{
                    @include pera1;
                    font-size: 16px;
                }
            }
            
        }

        .links{
            padding: 0;
            li{
                margin-bottom: 32px;
                box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.08);
                padding: 40px 24px;
                h3{
                    font-family: $mono;
                    font-size: 24px;
                    color: $black-light;
                    font-weight: 700;
                    margin-bottom: 16px;
                }
                a{
                    color: #06B186;
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}

