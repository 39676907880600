@import './../../../styles/root.scss';

.categories_item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: $mono;
    font-size: 18px;
    color: $dusk;
    margin-bottom: 20px;

    input {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }
}