@import './../../../styles/root.scss';

.feature_card{
    margin-bottom: 20px;
    padding: 24px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid $white;
    transition: t(0.3);
    .head{
        height: 180px;
        .icon_box{
            height: 80px;
            width: 80px;
            border-radius: 8px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;

            svg{
                font-size: 36px;
                fill: #4DAC9D;
            }
        }
        h3{
            font-family: $mono;
            font-size: 20px;
            font-weight: 700;
            color: $white;
        }
    }
    .body{
        height: 235px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .body_text{
            .line{
                width: 70%;
                height: 4px;
                background-color: $aqua;
                transition: t(0.3);
            }
            p{
               font-family: $mono;
               font-size: 18px;
               font-weight: 400;
               color: $white; 
               margin: 16px 0px;
               text-align: left;
            }
        }

        a{
            padding: 8px 24px;
            background-color: $white;
            font-family: $mono;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
        }
    }

    &:hover{
        background-color: $aqua;
        border-color: $aqua;
        .body{
            .line{
                background-color: $white;
            }
        }
    }
}