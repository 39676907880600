@import './../../../styles/root.scss';

.blog_card{
    border: none;

    .head{
        .imgbox{
            img{
                transition: t(0.3);
            }
        }
    }

    .body{
        margin-top: 20px;
        h2{
            font-family: $mono;
            font-size: 20px;
            font-weight: 700;
            color: $black-light;
            margin-bottom: 16px;
        }
        p{
            @include pera1;
            font-size: 16px;
            margin-bottom: 25px;
        }

        a{
            font-family: $mono;
            font-size: 16px;
            font-weight: 600;
            color: $aqua;
            border-bottom: 1px solid $aqua;
            cursor: pointer;
            i{
                font-size: 24px;
                transform: rotate(45deg) translateY(7px);
                margin-left: 8px;
                cursor: pointer;
            }
        }
    }

    &:hover{
        .head{
            .imgbox{
                img{
                    transform: scale(1.2);
                }
            }
        }
    }
}