@import './../../../styles/root.scss';

.fill_section {
    padding: 120px 0px;

    .fill_text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2{
            @include heading1;
        }
        p{
          @include pera1;  
        }
    }

    .fill_img{
        padding: 40px;
        background-color: $mag;
    }
}