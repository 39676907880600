@import './../../../styles/root.scss';

.card.event_card{
    border: none;
    background-color: transparent;
    box-shadow: none;

    .head{
        border-radius: 10px;
        overflow: hidden;
    }
    .body{
        padding: 20px;
        margin: -40px 20px 0px 20px;
        background-color: $white;
        border-radius: 10px;
        border-top: 4px solid $aqua;
        z-index: 1;
        .heading{
            font-size: 18px;
            font-weight: 600;
            color: $black;
            margin-bottom: 20px;
            font-family: $mono;
        }
        .tag_box{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 20px;

            .tag{
                background-color: $aqua;
                padding: 8px 20px;
                border-radius: 20px;
                font-size: 14px;
                font-weight: 500;
            }
            .date{
                font-size: 14px;
                color: #00000050;
                svg{
                    margin-right: 5px;
                }
            }
        }
        .pera{
            font-size: 14px;
            color: #00000050;
        }
        .icon_with_text{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
            li{
                font-size: 14px;
                color: #00000050;

                svg{
                    margin-right: 5px;
                }
            }
        }
    }
}